<template>
    <div>
        <input hidden type="text" class="form-control" id="PayrollId" v-model="PayrollId">
        <div class="modal fade" id="payrollModal" tabindex="-1" aria-labelledby="payrollModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="payrollModalLabel" class="font-weight-bold">{{ModalTitle}}{{PayrollId}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold required">Nama Karyawan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <v-select id="EmployeeName" class="pb-3" :options="EmployeeIdData" @input="onChangeEmployeeId()" :disabled="DisabledEmp ? false : true" />
                                    <label id="errorEmployeeName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow class="pb-3">
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold required">Bulan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <date-picker
                                        id="Month"
                                        v-model="Month"
                                        @input="monthSelected()"
                                        format="MMM"
                                        type="month"
                                        placeholder="Select Month"
                                        value-type="M"
                                        style="width:100%"
                                        :disabled="DisabledEmp ? false : true"
                                    ></date-picker>
                                    <label id="errorMonth" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold required">Tahun</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <date-picker
                                        id="Year"
                                        v-model="Year"
                                        @input="yearSelected()"
                                        format="YYYY"
                                        type="year"
                                        placeholder="Select Year"
                                        value-type="YYYY"
                                        style="width:100%"
                                        :disabled="DisabledEmp ? false : true"
                                    ></date-picker>                                    
                                    <label id="errorYear" class="form-error" style="display: none; color: red;"></label>
                                </CCol> 
                            </CRow>
                            <hr>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Total Kehadiran</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="TotalAttendance" type="number" v-model="TotalAttendance" class="font-weight-bold" />
                                    <label id="errorTotalAttendance" class="form-error" style="display: none; color: red;"></label>
                                </CCol> 
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Total Gaji</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="TotalSalary" type="number" v-model="TotalSalary" class="font-weight-bold"/>
                                    <label id="errorTotalSalary" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                         
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Tunjangan Harian</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="DailyAllowance" type="number" v-model="DailyAllowance" class="font-weight-bold"/>
                                    <label id="errorDailyAllowance" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Gaji Pokok</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="BaseSalary" type="number" v-model="BaseSalary" class="font-weight-bold"/>
                                    <label id="errorBaseSalary" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                        
                            </CRow>
                            <CRow> 
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Tunjangan Makan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="MealAllowance" type="number" v-model="MealAllowance" class="font-weight-bold" />
                                    <label id="errorMealAllowance" class="form-error" style="display: none; color: red;"></label>
                                </CCol>  
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Bonus</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="Bonus" type="number" v-model="Bonus" class="font-weight-bold"/>
                                    <label id="errorBonus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                            
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Gaji Harian</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="DailySalary" type="number" v-model="DailySalary" class="font-weight-bold"/>
                                    <label id="errorDailySalary" class="form-error" style="display: none; color: red;"></label>
                                </CCol>  
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Total Gaji Harian</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="TotalDailySalary" type="number" v-model="TotalDailySalary" class="font-weight-bold"/>
                                    <label id="errorTotalDailySalary" class="form-error" style="display: none; color: red;"></label>
                                </CCol>    
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Uang Transport</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="TransportAllowance" type="number" v-model="TransportAllowance" class="font-weight-bold"/>
                                    <label id="errorTransportAllowance" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Tunjangan Staff Tetap</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="FixPositionalAllowance" type="number" v-model="FixPositionalAllowance" class="font-weight-bold" />
                                    <label id="errorFixPositionalAllowance" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                      
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Tunjangan Kendaraan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="MotorcycleRentalAllowance" type="number" v-model="MotorcycleRentalAllowance" class="font-weight-bold" />
                                    <label id="errorMotorcycleRentalAllowance" class="form-error" style="display: none; color: red;"></label>
                                </CCol>  
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Tunjangan Harian Tetap</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="DailyPositionalAllowance" type="number" v-model="DailyPositionalAllowance" class="font-weight-bold"/>
                                    <label id="errorDailyPositionalAllowance" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Tunjangan Perjalanan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="TripAllowance" type="number" v-model="TripAllowance" class="font-weight-bold"/>
                                    <label id="errorTripAllowance" class="form-error" style="display: none; color: red;"></label>
                                </CCol> 
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Tunjangan Pulsa</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="PulseAllowance" type="number" v-model="PulseAllowance" class="font-weight-bold"/>
                                    <label id="errorPulseAllowance" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                 
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Tunjangan Kost</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="KostAllowance" type="number" v-model="KostAllowance" class="font-weight-bold"/>
                                    <label id="errorKostAllowance" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Tunjangan Lain</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="OtherAllowance" type="number" v-model="OtherAllowance" class="font-weight-bold"/>
                                    <label id="errorOtherAllowance" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                     
                            </CRow>
                            <hr>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Pemotongan Keterlambatan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="LateSubtraction" type="number" v-model="LateSubtraction" class="font-weight-bold"/>
                                    <label id="errorLateSubtraction" class="form-error" style="display: none; color: red;"></label>
                                </CCol>    
                                 <CCol sm="3">
                                    <label class="form-label font-weight-bold">Total Terlambat</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="TotalLate" type="number" v-model="TotalLate" class="font-weight-bold"/>
                                    <label id="errorTotalLate" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Pemotongan Ketidakhadiran</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="AbsenceSubtraction" type="number" v-model="AbsenceSubtraction" class="font-weight-bold"/>
                                    <label id="errorAbsenceSubtraction" class="form-error" style="display: none; color: red;"></label>
                                </CCol> 
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Pemotongan Ketidakrajinan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="CraftSubtraction" type="number" v-model="CraftSubtraction" class="font-weight-bold"/>
                                    <label id="errorCraftSubtraction" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                            
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Pemotongan Asuransi/BPJS</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="InsuranceSubtraction" type="number" v-model="InsuranceSubtraction" class="font-weight-bold" />
                                    <label id="errorInsuranceSubtraction" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Total Utang</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="TotalDebt" type="number" v-model="TotalDebt" class="font-weight-bold"/>
                                    <label id="errorTotalDebt" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                                                                 
                            </CRow>
                             <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Pemotongan Utang</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="DebtSubtraction"  type="number" v-model="DebtSubtraction" class="font-weight-bold"/>
                                    <label id="errorDebtSubtraction" class="form-error" style="display: none; color: red;"></label>
                                </CCol> 
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Sisa Utang</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="RemainingDebt" type="number" v-model="RemainingDebt" class="font-weight-bold"/>
                                    <label id="errorRemainingDebt" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                               
                            </CRow>
                            <hr>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Total Absen</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="TotalAbsence"  type="number" v-model="TotalAbsence" class="font-weight-bold"/>
                                    <label id="errorTotalAbsence" class="form-error" style="display: none; color: red;"></label>
                                </CCol> 
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Sisa Cuti </label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="RemainingPaidLeave" type="number" v-model="RemainingPaidLeave" class="font-weight-bold"/>
                                    <label id="errorRemainingPaidLeave" class="form-error" style="display: none; color: red;"></label>
                                </CCol>    
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Cuti Bulanan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="MonthlyPaidLeave" type="number" v-model="MonthlyPaidLeave" class="font-weight-bold"/>
                                    <label id="errorMonthlyPaidLeave" class="form-error" style="display: none; color: red;"></label>
                                </CCol> 
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Cuti Tahunan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="AnnualPaidLeave" type="number" v-model="AnnualPaidLeave" class="font-weight-bold"/>
                                    <label id="errorAnnualPaidLeave" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                        
                            </CRow>
                            <br>
                            <label id="errorPayrollGeneral" class="form-error" style="display: none; color: red;"></label>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import payrollService from '../Script/PayrollService.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'PayrollForm',
    props: ['reload'],
    components: {
        'date-picker' : datePicker,        
    },
    data: function () {
        return {
            ModalTitle: '',

            PayrollId: '',
            Employee: '',
            EmployeeName: '',
            Department: '',
            TotalSalary: 0,
            EmployeeIdData: [],
            Month: null,
            Year: null,
            YearNumber: null,
            MonthNumber: null,
            TotalAttendance: 0,
            MealAllowance: 0,
            DailyAllowance: 0,
            TransportAllowance: 0,
            DailySalary: 0,
            TotalDailySalary: 0,
            FixPositionalAllowance: 0,
            DailyPositionalAllowance: 0,
            MotorcycleRentalAllowance: 0,
            TripAllowance: 0,
            KostAllowance: 0,
            PulseAllowance: 0,
            InsuranceSubtraction: 0,
            LateSubtraction: 0,
            AbsenceSubtraction: 0,
            BaseSalary: 0,
            CraftSubtraction: 0,
            DebtSubtraction: 0,
            TotalDebt: 0,
            OtherAllowance: 0,
            TotalLate: 0,
            TotalAbsence: 0,
            AnnualPaidLeave: 0,
            RemainingPaidLeave: 0,
            MonthlyPaidLeave: 0,
            RemainingDebt: 0,
            Bonus: 0,
            CreatedAt: '',
            LastUpdate: '',

            Error: 0,
            SaveType: '',
            DisabledEmp: true
        }
    },
    methods: {
        async addClick(){  
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.DisabledEmp = true
            this.ModalTitle = 'Add Payroll';

            this.Employee = '';
            this.EmployeeIdData = await payrollService.getEmployeeIdQuery();
            this.Month = null;
            this.Year = null;
            this.YearNumber =  null;
            this.MonthNumber = null;

            this.resetPayrollData();
            this.totalSalaryCount();

            this.RemainingDebt = 0;
            this.Bonus = 0;

            this.SaveType = 'Add';

            window.$('#payrollModal').modal('show');
        },
        async editClick(data, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();

            this.ModalTitle = 'Edit Payroll: ';
            this.DisabledEmp = false;   

            const variables = {
                payroll_id : data.payroll_id,
                month : data.month,
                year : data.year
            };
            
            var payrollData = await payrollService.readQueryPayrollById(variables);
            this.PayrollId = payrollData.payroll_id; 
            this.EmployeeIdData = await payrollService.getEmployeeIdQuery();
            this.Employee = this.EmployeeIdData.find(c => c.value == payrollData.employee_id);
            this.Month = payrollData.month + '-' + payrollData.year;
            this.MonthNumber = payrollData.month;
            this.Year = payrollData.month + '-'+ payrollData.year;
            this.YearNumber = payrollData.year;
            this.TotalAttendance = payrollData.total_attendance;
            this.MealAllowance = payrollData.meal_allowance;
            this.DailyAllowance = payrollData.daily_allowance;
            this.TransportAllowance = payrollData.transport_allowance;
            this.DailySalary = payrollData.daily_salary;
            this.TotalDailySalary = payrollData.total_daily_salary;
            this.FixPositionalAllowance = payrollData.fixed_positional_allowance;
            this.DailyPositionalAllowance = payrollData.daily_positional_allowance;
            this.MotorcycleRentalAllowance = payrollData.motorcycle_rental_allowance;
            this.TripAllowance = payrollData.trip_allowance;
            this.KostAllowance = payrollData.kost_allowance;
            this.PulseAllowance = payrollData.pulse_allowance;
            this.InsuranceSubtraction = payrollData.insurance_subtraction;
            this.LateSubtraction = payrollData.late_subtraction;
            this.AbsenceSubtraction = payrollData.absence_subtraction;
            this.BaseSalary = payrollData.base_salary;
            this.CraftSubtraction = payrollData.craft_subtraction;
            this.DebtSubtraction = payrollData.debt_subtraction;
            this.TotalDebt = payrollData.total_debt;
            this.OtherAllowance = payrollData.other_allowance;
            this.TotalLate = payrollData.total_late;
            this.TotalAbsence = payrollData.total_absence;
            this.AnnualPaidLeave = payrollData.annual_paid_leave;
            this.RemainingPaidLeave = payrollData.remaining_paid_leave;
            this.MonthlyPaidLeave = payrollData.montly_paid_leave;
            
            this.totalSalaryCount();

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Payroll: ';
            };

            window.$('#payrollModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.EmployeeName == '' ){
                this.errorShow('errorEmployeeName');
            }

            if(this.Month == null || this.MonthNumber == null){
                this.errorShow('errorMonth');
            }

            if(this.Year == null || this.YearNumber == null){
                this.errorShow('errorYear');
            }

            if(this.TotalAttendance.toString() == '' || this.TotalAttendance == null){
                this.errorShow('errorTotalAttendance');
            }
            else if (this.TotalAttendance < 0){
                this.errorShow('errorTotalAttendance', response.fieldRequiredNotMinus);
            }

            if(this.MealAllowance.toString() == '' || this.MealAllowance == null){
                this.errorShow('errorMealAllowance');
            }
            else if (this.MealAllowance < 0){
                this.errorShow('errorMealAllowance', response.fieldRequiredNotMinus);
            }

            if(this.DailyAllowance.toString() == '' || this.DailyAllowance == null){
                this.errorShow('errorDailyAllowance');
            }
            else if (this.DailyAllowance < 0){
                this.errorShow('errorDailyAllowance', response.fieldRequiredNotMinus);
            }

            if(this.TransportAllowance.toString() == '' || this.TransportAllowance == null){
                this.errorShow('errorTransportAllowance');
            }
            else if (this.TransportAllowance < 0){
                this.errorShow('errorTransportAllowance', response.fieldRequiredNotMinus);
            }

            if(this.DailySalary.toString() == '' || this.DailySalary == null){
                this.errorShow('errorDailySalary');
            }
            else if (this.DailySalary < 0){
                this.errorShow('errorDailySalary', response.fieldRequiredNotMinus);
            }

            if(this.TotalDailySalary.toString() == '' || this.TotalDailySalary == null){
                this.errorShow('errorTotalDailySalary');
            }
            else if (this.TotalDailySalary < 0){
                this.errorShow('errorTotalDailySalary', response.fieldRequiredNotMinus);
            }

            if(this.FixPositionalAllowance.toString() == '' || this.FixPositionalAllowance == null){
                this.errorShow('errorFixPositionalAllowance');
            }
            else if (this.FixPositionalAllowance < 0){
                this.errorShow('errorFixPositionalAllowance', response.fieldRequiredNotMinus);
            }

            if(this.DailyPositionalAllowance.toString() == '' || this.DailyPositionalAllowance == null){
                this.errorShow('errorDailyPositionalAllowance');
            }
            else if (this.DailyPositionalAllowance < 0){
                this.errorShow('errorDailyPositionalAllowance', response.fieldRequiredNotMinus);
            }

            if(this.MotorcycleRentalAllowance.toString() == '' || this.MotorcycleRentalAllowance == null){
                this.errorShow('errorMotorcycleRentalAllowance');
            }
            else if (this.MotorcycleRentalAllowance < 0){
                this.errorShow('errorMotorcycleRentalAllowance', response.fieldRequiredNotMinus);
            }

            if(this.TripAllowance.toString() == ''  || this.TripAllowance == null){
                this.errorShow('errorTripAllowance');
            }
            else if (this.TripAllowance < 0){
                this.errorShow('errorTripAllowance', response.fieldRequiredNotMinus);
            }

            if(this.KostAllowance.toString() == '' || this.KostAllowance == null ){
                this.errorShow('errorKostAllowance');
            }
            else if (this.KostAllowance < 0){
                this.errorShow('errorKostAllowance', response.fieldRequiredNotMinus);
            }

            if(this.PulseAllowance.toString() == '' || this.PulseAllowance == null){
                this.errorShow('errorPulseAllowance');
            }
            else if (this.PulseAllowance < 0){
                this.errorShow('errorPulseAllowance', response.fieldRequiredNotMinus);
            }

            if(this.InsuranceSubtraction.toString() == '' || this.InsuranceSubtraction == null){
                this.errorShow('errorInsuranceSubtraction');
            }
            else if (this.InsuranceSubtraction < 0){
                this.errorShow('errorInsuranceSubtraction', response.fieldRequiredNotMinus);
            }

            if(this.LateSubtraction.toString() == '' || this.LateSubtraction ==null){
                this.errorShow('errorLateSubtraction');
            }
            else if (this.LateSubtraction < 0){
                this.errorShow('errorLateSubtraction', response.fieldRequiredNotMinus);
            }

            if(this.AbsenceSubtraction.toString() == '' || this.AbsenceSubtraction == null){
                this.errorShow('errorAbsenceSubtraction');
            }
            else if (this.AbsenceSubtraction < 0){
                this.errorShow('errorAbsenceSubtraction', response.fieldRequiredNotMinus);
            }

            if(this.BaseSalary.toString() == '' || this.BaseSalary ==  null){
                this.errorShow('errorBaseSalary');
            }
            else if (this.BaseSalary < 0){
                this.errorShow('errorBaseSalary', response.fieldRequiredNotMinus);
            }

            if(this.CraftSubtraction.toString() == '' || this.CraftSubtraction == null){
                this.errorShow('errorCraftSubtraction');
            }
            else if (this.CraftSubtraction < 0){
                this.errorShow('errorCraftSubtraction', response.fieldRequiredNotMinus);
            }

            if(this.DebtSubtraction.toString() == '' || this.DebtSubtraction == null){
                this.errorShow('errorDebtSubtraction');
            }
            else if (this.DebtSubtraction < 0){
                this.errorShow('errorDebtSubtraction', response.fieldRequiredNotMinus);
            }

            if(this.TotalDebt.toString() == '' || this.TotalDebt == null){
                this.errorShow('errorTotalDebt');
            }
            else if (this.TotalDebt < 0){
                this.errorShow('errorTotalDebt', response.fieldRequiredNotMinus);
            }

            if(this.OtherAllowance.toString() == '' || this.OtherAllowance ==null){
                this.errorShow('errorOtherAllowance');
            }
            else if (this.OtherAllowance < 0){
                this.errorShow('errorOtherAllowance', response.fieldRequiredNotMinus);
            }

            if(this.TotalAbsence.toString() == '' | this.TotalAbsence == null){
                this.errorShow('errorTotalAbsence');
            }
            else if (this.TotalAbsence < 0){
                this.errorShow('errorTotalAbsence', response.fieldRequiredNotMinus);
            }

            if(this.TotalLate.toString() == '' || this.TotalLate == null){
                this.errorShow('errorTotalLate');
            }
            else if (this.TotalLate < 0){
                this.errorShow('errorTotalLate', response.fieldRequiredNotMinus);
            }

            if(this.AnnualPaidLeave.toString() == '' || this.AnnualPaidLeave == null){
                this.errorShow('errorAnnualPaidLeave');
            }
            else if (this.AnnualPaidLeave < 0){
                this.errorShow('errorAnnualPaidLeave', response.fieldRequiredNotMinus);
            }

            if(this.RemainingPaidLeave.toString() == '' || this.RemainingPaidLeave ==null){
                this.errorShow('errorRemainingPaidLeave');
            }
            else if (this.RemainingPaidLeave < 0){
                this.errorShow('errorRemainingPaidLeave', response.fieldRequiredNotMinus);
            }

            if(this.MonthlyPaidLeave.toString() == '' || this.MonthlyPaidLeave == null){
                this.errorShow('errorMonthlyPaidLeave');
            }
            else if (this.MonthlyPaidLeave < 0){
                this.errorShow('errorMonthlyPaidLeave', response.fieldRequiredNotMinus);
            }
        },
        saveClick() {
            this.inputValidation();
            
            if(this.Error == 0){
                const payrollData = {
                    employee_id: this.Employee.value,
                    month: this.MonthNumber,
                    year: this.YearNumber,
                    total_attendance: this.TotalAttendance,
                    meal_allowance: this.MealAllowance,
                    daily_allowance: this.DailyAllowance,
                    transport_allowance: this.TransportAllowance,
                    daily_salary: this.DailySalary,
                    total_daily_salary: this.TotalDailySalary,
                    fixed_positional_allowance: this.FixPositionalAllowance,
                    daily_positional_allowance: this.DailyPositionalAllowance,
                    motorcycle_rental_allowance: this.MotorcycleRentalAllowance,
                    trip_allowance: this.TripAllowance,
                    kost_allowance: this.KostAllowance,
                    pulse_allowance: this.PulseAllowance,
                    insurance_subtraction: this.InsuranceSubtraction,
                    late_subtraction: this.LateSubtraction,
                    absence_subtraction: this.AbsenceSubtraction,
                    base_salary: this.BaseSalary,
                    craft_subtraction: this.CraftSubtraction,
                    debt_subtraction: this.DebtSubtraction,
                    total_debt: this.TotalDebt,
                    other_allowance: this.OtherAllowance,
                    total_late: this.TotalLate,
                    total_absence: this.TotalAbsence,
                    annual_paid_leave: this.AnnualPaidLeave,
                    remaining_paid_leave: this.RemainingPaidLeave,
                    montly_paid_leave:this.MonthlyPaidLeave,
                    total_salary: this.TotalSalary,
                    bonus: this.Bonus,
                    remaining_debt: this.RemainingDebt,
                };

                this.$loading(true);

                //Add
                if(this.SaveType == 'Add'){
                    const variables = {
                        data : payrollData
                    }

                    payrollService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#payrollModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error);
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    const variables = {
                        id : this.PayrollId,
                        data : payrollData
                    }
                    
                    payrollService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#payrollModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error);
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode, message){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = message == undefined ? response.fieldRequired : message;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        monthSelected(){
            this.MonthNumber = this.Month;
            this.onChangeEmployeeId();
        },
        yearSelected(){
            this.YearNumber = this.Year;
            this.onChangeEmployeeId();
        },
        onChangeEmployeeId(){
            if(this.Employee == null || this.Employee == ''){
                this.resetPayrollData()
            } else {
                if(this.Month == null || this.Month == ''){
                    this.resetPayrollData()
                } else {
                    var currentDate = new Date();
                    var month = this.Month == null ? currentDate.getMonth() + 1 : this.Month;
                    var year = this.Year == null ? currentDate.getFullYear() : this.Year;

                    const variables = {
                        employee_id : parseInt(this.Employee.value),
                        month: parseInt(month),
                        year: parseInt(year)
                    }
                    this.changePayrolData(variables);
                };
            };
        },
        async changePayrolData(variables){
            await payrollService.getPayrollEstimation(variables).then(res => {
                var payrollEstimationData = res;

                this.EmployeeName= this.Employee.label;
                this.MonthNumber = payrollEstimationData.month;
                this.YearNumber = payrollEstimationData.year;
                this.Month = payrollEstimationData.month + '-' + payrollEstimationData.year;
                this.Year = payrollEstimationData.month + '-' + payrollEstimationData.year;
                this.TotalAttendance = payrollEstimationData.total_attendance;
                this.MealAllowance = payrollEstimationData.meal_allowance;
                this.DailyAllowance = payrollEstimationData.meal_allowance;
                this.TransportAllowance = payrollEstimationData.transport_allowance;
                this.DailySalary = payrollEstimationData.daily_salary;
                this.TotalDailySalary = payrollEstimationData.total_daily_salary;
                this.FixPositionalAllowance = payrollEstimationData.fixed_positional_allowance;
                this.DailyPositionalAllowance = payrollEstimationData.daily_positional_allowance;
                this.MotorcycleRentalAllowance = payrollEstimationData.motorcycle_rental_allowance;
                this.TripAllowance = payrollEstimationData.trip_allowance;
                this.KostAllowance = payrollEstimationData.kost_allowance;
                this.PulseAllowance = payrollEstimationData.pulse_allowance;
                this.InsuranceSubtraction = payrollEstimationData.insurance_subtraction;
                this.LateSubtraction = payrollEstimationData.late_subtraction;
                this.AbsenceSubtraction = payrollEstimationData.absence_subtraction;
                this.BaseSalary = payrollEstimationData.base_salary;
                this.CraftSubtraction = payrollEstimationData.craft_subtraction;
                this.DebtSubtraction = payrollEstimationData.debt_subtraction;
                this.TotalDebt = payrollEstimationData.total_debt;
                this.OtherAllowance = payrollEstimationData.other_allowance;
                this.TotalLate = payrollEstimationData.total_late;
                this.TotalAbsence = payrollEstimationData.total_absence;
                this.AnnualPaidLeave = payrollEstimationData.annual_paid_leave;
                this.RemainingPaidLeave = payrollEstimationData.remaining_paid_leave;
                this.MonthlyPaidLeave = payrollEstimationData.montly_paid_leave;

                this.totalSalaryCount();
            }).catch(error => {
                this.resetPayrollData();
                var errorMessage = globalfunc.errorExtractor(error);
                this.$swal("Error", errorMessage, "error");
            })
        },
        resetPayrollData(){
            this.PayrollId = '';
            this.TotalAttendance = 0;
            this.MealAllowance = 0;
            this.DailyAllowance = 0;
            this.TransportAllowance = 0;
            this.DailySalary = 0;
            this.TotalDailySalary = 0;
            this.FixPositionalAllowance = 0;
            this.DailyPositionalAllowance = 0;
            this.MotorcycleRentalAllowance = 0;
            this.TripAllowance = 0;
            this.KostAllowance = 0;
            this.PulseAllowance = 0;
            this.InsuranceSubtraction = 0;
            this.LateSubtraction = 0;
            this.AbsenceSubtraction = 0;
            this.BaseSalary = 0;
            this.CraftSubtraction = 0;
            this.DebtSubtraction = 0;
            this.TotalDebt = 0;
            this.OtherAllowance = 0;
            this.TotalLate = 0;
            this.TotalAbsence = 0;
            this.AnnualPaidLeave = 0;
            this.RemainingPaidLeave = 0;
            this.MonthlyPaidLeave = 0;

            this.totalSalaryCount();
        },
        totalSalaryCount(){
            this.TotalSalary = this.DailySalary + this.Bonus + this.BaseSalary + this.FixPositionalAllowance + this.DailyPositionalAllowance + 
                               this.MotorcycleRentalAllowance + this.TripAllowance + this.KostAllowance + this.PulseAllowance + this.AbsenceSubtraction + 
                               this.CraftSubtraction + this.InsuranceSubtraction + this.DebtSubtraction;
        }
    }
}
</script>
