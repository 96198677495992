<template>
    <div>
        <input hidden type="text" class="form-control" id="AttendanceId" v-model="AttendanceId">
        <div class="modal fade" id="attendanceModal" tabindex="-1" aria-labelledby="attendanceModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="attendanceModalLabel" class="font-weight-bold">{{ModalTitle}}{{AttendanceId}}</h5>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Nama Karyawan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <v-select id="EmployeeId" class="pb-3" :options="EmployeeIdData" v-model="EmployeeId" @input="onChangeEmployeeId" :disabled="disabledEmp ? false : true" />
                                    <label id="errorEmployeeId" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>

                            <CRow class="pb-3">
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Bulan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <date-picker
                                        id="Month"
                                        v-model="Month"
                                        @input="monthSelected()"
                                        format="MMM"
                                        type="month"
                                        placeholder="Select Month"
                                        value-type="M"
                                        style="width:100%"
                                    ></date-picker>
                                    <label id="errorMonth" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>

                            <CRow class="pb-3">
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Tahun</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <date-picker
                                        id="Year"
                                        v-model="Year"
                                        @input="yearSelected()"
                                        format="YYYY"
                                        type="year"
                                        placeholder="Select Year"
                                        value-type="YYYY"
                                        style="width:100%"
                                    ></date-picker>                                    
                                    <label id="errorYear" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>

                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Total Kehadiran</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="TotalAttendance" type="number" v-model="TotalAttendance" class="font-weight-bold" />
                                    <label id="errorTotalAttendance" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>

                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Total Hari Kerja</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="TotalWorkingDay" type="number" v-model="TotalWorkingDay" class="font-weight-bold"/>
                                    <label id="errorTotalWorkingDay" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>

                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Cuti Tidak Dibayar</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="TotalUpaidLeave" type="number" v-model="TotalUnpaidLeave" class="font-weight-bold"/>
                                    <label id="errorTotalUnpaidLeave" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>

                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Cuti Dibayar</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="TotalPaidLeave" type="number" v-model="TotalPaidLeave" class="font-weight-bold" />
                                    <label id="errorTotalPaidLeave" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>

                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Total Terlambat</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="TotalLate" type="number" v-model="TotalLate" class="font-weight-bold"/>
                                    <label id="errorTotalLate" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <br>
                            <label id="errorAttendanceGeneral" class="form-error" style="display: none; color: red;"></label>
                            <br>

                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import attendanceService from '../Script/AttendanceService.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'AttendanceForm',
    props: ['reload'],
    components: {
        'date-picker' : datePicker,        
    },
    data: function () {
        return {
            ModalTitle : '',
            AttendanceId:'',

            EmployeeId: '',
            EmployeeIdNumber:'',
            EmployeeIdData : [],

            Year: '',
            Month: '',
            YearNumber: '',
            MonthNumber: '',

            TotalAttendance: '',
            TotalWorkingDay: '',
            TotalUnpaidLeave: '',
            TotalPaidLeave:'',
            TotalLate:'',

            Error: 0,
            SaveType: '',
            disabledEmp: true
        }
    },
    methods: {
        async addClick(){  
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.disabledEmp = true;
            
            this.ModalTitle = 'Add Attendance';

            this.EmployeeId = '';
            this.EmployeeIdData = await attendanceService.getEmployeeIdQuery();

            this.Month = '';
            this.Year = '',
            this.TotalAttendance = 0;
            this.TotalWorkingDay = 0;
            this.TotalUnpaidLeave = 0;
            this.TotalPaidLeave = 0;
            this.TotalLate = 0,

            this.SaveType = 'Add';
            window.$('#attendanceModal').modal('show');
        },
        async editClick(month, year, employeeId, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();

            var data = await attendanceService.getAttendanceData(month, year, employeeId);

            const Month = data.month.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false
            });

            this.disabledEmp = false;
            this.ModalTitle = 'Edit Attendance: ';  
            this.AttendanceId = data.attendance_id; 
            this.EmployeeIdData = await attendanceService.getEmployeeIdQuery(); 
            this.EmployeeId = this.EmployeeIdData.find(c => c.value == data.employee_Id);

            this.Month = Month + '-' + data.year;
            this.MonthNumber = data.month;

            this.Year = Month + '-'+ data.year;
            this.YearNumber = data.year;

            this.TotalLate = data.total_late;
            this.TotalAttendance = data.total_attendance;
            this.TotalWorkingDay = data.total_working_day;
            this.TotalUnpaidLeave = data.total_unpaid_leave;
            this.TotalPaidLeave = data.total_paid_leave;

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Attendance: ';
            }

            window.$('#attendanceModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");
            
            if(this.EmployeeId == '' || this.EmployeeId == null){
                this.errorShow('errorEmployeeId');
            }
            if(this.Month == '' || this.MonthNumber == null){
                this.errorShow('errorMonth');
            }
            if(this.Year == '' || this.YearNumber == null){
                this.errorShow('errorYear');
            }
            if(this.TotalAttendance.toString() == '' || this.TotalAttendance == null){
                this.errorShow('errorTotalAttendance');
            }
            else if (this.TotalAttendance < 0) {
                this.errorShow('errorTotalAttendance', response.fieldRequiredNotMinus);
            }
            if(this.TotalWorkingDay.toString() == '' || this.TotalWorkingDay == null){
                this.errorShow('errorTotalWorkingDay');
            }
            else if (this.TotalWorkingDay < 0) {
                this.errorShow('errorTotalWorkingDay', response.fieldRequiredNotMinus);
            }
            if(this.TotalUnpaidLeave.toString() == '' || this.TotalUnpaidLeave == null){
                this.errorShow('errorTotalUnpaidLeave');
            }
            else if (this.TotalUnpaidLeave < 0) {
                this.errorShow('errorTotalUnpaidLeave', response.fieldRequiredNotMinus);
            }
            if(this.TotalPaidLeave.toString() == '' || this.TotalPaidLeave == null){
                this.errorShow('errorTotalPaidLeave');
            }
            else if (this.TotalPaidLeave < 0) {
                this.errorShow('errorTotalPaidLeave', response.fieldRequiredNotMinus);
            }
            if(this.TotalLate.toString() == '' || this.TotalLate == null){
                this.errorShow('errorTotalLate');
            }
            else if (this.TotalLate < 0) {
                this.errorShow('errorTotalLate', response.fieldRequiredNotMinus);
            }
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const attendanceData = {
                        employee_Id: this.EmployeeId.value,
                        month: this.Month,
                        year: this.Year,
                        total_attendance: this.TotalAttendance,
                        total_working_day: this.TotalWorkingDay,
                        total_unpaid_leave: this.TotalUnpaidLeave,
                        total_paid_leave: this.TotalPaidLeave,
                        total_late: this.TotalLate
                    };

                    const variables = {
                        data : attendanceData
                    }

                    attendanceService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#attendanceModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error);
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const attendanceData = {
                        employee_Id: this.EmployeeId.value,
                        month: this.MonthNumber,
                        year: this.YearNumber,
                        total_attendance: parseInt(this.TotalAttendance),
                        total_working_day: parseInt(this.TotalWorkingDay),
                        total_unpaid_leave: parseInt(this.TotalUnpaidLeave),
                        total_paid_leave: parseInt(this.TotalPaidLeave), 
                        total_late: parseInt(this.TotalLate)                    
                    };

                    const variables = {
                        id : this.AttendanceId,
                        data : attendanceData
                    }

                    attendanceService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#attendanceModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error);
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode, message){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = message == undefined ? response.fieldRequired : message;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        onChangeEmployeeId(){
            this.EmployeeIdNumber = this.EmployeeId == null ? '' : this.EmployeeId.value;
        },
        monthSelected(){
            this.MonthNumber = this.Month
        },
        yearSelected(){
            this.YearNumber = this.Year
        }
        
    }
}
</script>
